const GazableButton = ({ gazable = true, className, onClick, children }) => {
  return gazable ? (
    <div
      is="div-gazable"
      dwell-animation="ltr"
      onClick={onClick}
      class={className}
    >
      {children}
    </div>
  ) : (
    <div onClick={onClick} class={className}>
      {children}
    </div>
  );
};

export default GazableButton;
