import { useState, useEffect } from "react";
import VerticalCarousel from "./components/VerticalCarousel";
import ToolBar from "./components/ToolBar";
import { Mode, Theme, Layout, defaultVideoLists } from "./settings";

import { BackIcon } from "./components/Icons";
import VideoCard from "./components/VideoCard";
import "tdbrowse-web-components";
import "./App.scss";
import YouTubePlayer from "./components/YouTubePlayer";
import GazableButton from "./components/GazableButton";

const App = () => {
  const getStored = (key, defaultValue) =>
    JSON.parse(localStorage.getItem(key)) || defaultValue;
  const store = (key, value) =>
    localStorage.setItem(key, JSON.stringify(value));

  const [mode, setMode] = useState(Mode.List);
  const [theme, setTheme] = useState(getStored("theme", Theme.Dark));
  const [layout, setLayout] = useState(getStored("layout", Layout.Grid4));
  const [activePlaylist, setActivePlaylist] = useState(
    getStored("activePlaylist", "cats")
  );
  const [videoList, setVideoList] = useState(
    getStored(activePlaylist, defaultVideoLists[activePlaylist] || [])
  );
  const [activeVideo, setActiveVideo] = useState("");
  const [demo, setDemo] = useState("first");

  useEffect(() => store("theme", theme), [theme]);
  useEffect(() => store("layout", layout), [layout]);
  useEffect(() => {
    store("activePlaylist", activePlaylist);
    setVideoList(
      getStored(activePlaylist, defaultVideoLists[activePlaylist] || [])
    );
  }, [activePlaylist]);
  useEffect(
    () =>
      store(
        activePlaylist,
        videoList.filter((id) => id)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [videoList]
  );

  const toggleTheme = () => {
    setTheme(theme === Theme.Light ? Theme.Dark : Theme.Light);
  };

  const toggleMode = () => {
    setMode(mode === Mode.List ? Mode.Edit : Mode.List);
  };

  const toggleLayout = () => {
    setLayout(layout === Layout.Grid4 ? Layout.Grid6 : Layout.Grid4);
  };

  const toggleDemo = () => {
    setDemo(demo === "first" ? "second" : "first");
  };

  const deleteVideo = (index) => {
    const videoIds = [...videoList];
    videoIds.splice(index, 1);
    setVideoList(videoIds);
  };

  const updateVideo = (index, newVideoId) => {
    const videoIds = [...videoList];
    videoIds[index] = newVideoId;
    setVideoList(videoIds);
  };

  const addVideo = () => {
    const videoIds = [...videoList];
    videoIds.push("");
    setVideoList(videoIds);
  };

  const startVideo = (videoId) => {
    if (mode === Mode.List) {
      setActiveVideo(videoId);
      setMode(Mode.Watch);
    }
  };

  const changePlaylist = (newPlaylist) => {
    if (activePlaylist !== newPlaylist) setActivePlaylist(newPlaylist);
  };

  const data = [...videoList];
  if (mode === Mode.Edit) data.push(null);

  return (
    <div className={`app ${theme}`}>
      {mode === Mode.Watch && activeVideo ? (
        <>
          <GazableButton
            className="large-button back-button"
            onClick={toggleMode}
          >
            <BackIcon />
          </GazableButton>
          {<YouTubePlayer videoId={activeVideo} />}
        </>
      ) : (
        <div className="main-view">
          <VerticalCarousel
            rows={2}
            cols={layout === Layout.Grid4 ? 2 : 3}
            gazable={mode === Mode.List}
            demo={demo}
          >
            {data.map((videoId, index) => (
              <VideoCard
                key={`${videoId}-${index}`}
                videoId={videoId}
                editMode={mode === Mode.Edit}
                onDelete={() => deleteVideo(index)}
                onUpdate={(newUrl) => updateVideo(index, newUrl)}
                onAdd={addVideo}
                onStart={startVideo}
                ratioSm={layout === Layout.Grid6}
                imagesMode={demo === "first"}
              ></VideoCard>
            ))}
          </VerticalCarousel>
          <ToolBar
            mode={mode}
            theme={theme}
            layout={layout}
            activePlaylist={activePlaylist}
            gazable={mode === Mode.List}
            demo={demo}
            onToggleMode={toggleMode}
            onToggleTheme={toggleTheme}
            onToggleLayout={toggleLayout}
            onChangePlaylist={changePlaylist}
            onToggleDemo={toggleDemo}
          />
        </div>
      )}
    </div>
  );
};

export default App;
