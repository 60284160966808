import {
  SettingsIcon,
  Grid4Icon,
  Grid6Icon,
  LampOffIcon,
  LampOnIcon,
  CatIcon,
  DogIcon,
  HeartIcon,
} from "./Icons";
import { Mode, Theme, Layout } from "../settings";
import GazableButton from "./GazableButton";

const ToolBar = ({
  mode,
  theme,
  layout,
  gazable = true,
  activePlaylist,
  demo = "first",
  onToggleDemo = () => {},
  onToggleMode,
  onToggleTheme,
  onToggleLayout,
  onChangePlaylist,
}) => {
  const renderPlaylistButton = (playlistName, icon) => {
    const isActive = activePlaylist === playlistName;
    const isGazable = gazable && !isActive;
    return (
      <GazableButton
        className={`large-round-button ${isActive ? "active-playlist" : ""}`}
        gazable={isGazable}
        onClick={() => onChangePlaylist(playlistName)}
        key={`${playlistName}-${gazable ? "gazable" : ""}-${
          isActive ? "active" : ""
        }`}
      >
        {icon}
      </GazableButton>
    );
  };

  const editMode = mode === Mode.Edit;
  return (
    <div className="right-panel tool-bar">
      {(demo === "second" || editMode) && (
        <div className="top-buttons">
          {renderPlaylistButton("myList", <HeartIcon />)}
          {renderPlaylistButton("cats", <CatIcon />)}
          {renderPlaylistButton("dogs", <DogIcon />)}
        </div>
      )}
      <div className="bottom-buttons">
        {editMode && (
          <>
            <button className="large-round-button" onClick={onToggleTheme}>
              {theme === Theme.Dark ? <LampOnIcon /> : <LampOffIcon />}
            </button>
            <button className="large-round-button" onClick={onToggleLayout}>
              {layout === Layout.Grid4 ? <Grid6Icon /> : <Grid4Icon />}
            </button>
          </>
        )}
        <button
          className={`large-round-button ${editMode ? "active" : ""}`}
          onClick={onToggleMode}
        >
          <SettingsIcon />
        </button>
      </div>
    </div>
  );
};

export default ToolBar;
