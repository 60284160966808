export const getYouTubeVideoUrl = (videoId) =>
  `https://www.youtube.com/watch?v=${videoId}`;

export const getYouTubeEmbedUrl = (videoId) =>
  `https://www.youtube.com/embed/${videoId}`;

export const getYouTubeVideoId = (url) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=|www.youtube.com\/user\/.+#p\/u\/\d\/|youtu.be\/)([A-Za-z0-9_-]{11})/;
  const matches = url.match(regex);
  return matches ? matches[1] : "";
};
