import { DeleteIcon, PlayIcon, PlusIcon } from "./Icons";
import {
  getYouTubeEmbedUrl,
  getYouTubeVideoId,
  getYouTubeVideoUrl,
} from "../youtube-url-resolver";
import UrlEditor from "./UrlEditor";

import "./VideoCard.scss";
import { Button } from "react-bootstrap";
import VideoThumbnail from "./VideoThumbnail";
import VideoIframe from "./VideoIframe";
import GazableButton from "./GazableButton";

const renderCardEditOverlay = (url, onAdd, onDelete, onUpdate) => {
  return (
    <div className="overlay edit-mode">
      {url === null ? (
        <Button className="large-round-button" onClick={onAdd}>
          <PlusIcon />
        </Button>
      ) : (
        <>
          <Button
            className="small-round-button delete-button"
            onClick={onDelete}
          >
            <DeleteIcon />
          </Button>
          <UrlEditor initialUrl={url} onUpdate={onUpdate} />
        </>
      )}
    </div>
  );
};

const VideoCard = ({
  videoId,
  editMode,
  onUpdate,
  onDelete,
  onAdd,
  onStart,
  ratioSm = true,
  imagesMode = false,
}) => {
  let url = null;
  if (videoId !== null)
    url = imagesMode
      ? getYouTubeVideoUrl(videoId)
      : getYouTubeEmbedUrl(videoId);

  const updateVideo = (newUrl) => {
    const newVideoId = getYouTubeVideoId(newUrl);
    onUpdate(newVideoId);
  };

  return (
    <div
      className={`video-card-container  ${
        ratioSm ? "ratio-sm" : "ratio-lg"
      } border-rounded`}
    >
      {videoId &&
        (imagesMode ? (
          <VideoThumbnail videoId={videoId} className="full-space" />
        ) : (
          <VideoIframe url={url} className="full-space" />
        ))}
      {!editMode ? (
        <div className="overlay">
          <GazableButton
            className="large-button"
            onClick={() => onStart(videoId)}
          >
            <PlayIcon />
          </GazableButton>
        </div>
      ) : (
        renderCardEditOverlay(url, onAdd, onDelete, updateVideo)
      )}
    </div>
  );
};

export default VideoCard;
