import "./VerticalCarousel.scss";
import { AngelsDownIcon, AngelsUpIcon } from "./Icons";
import { useState } from "react";
import "tdbrowse-web-components";
import GazableButton from "./GazableButton";

const VerticalCarousel = ({ rows, cols, gazable, children: items, demo }) => {
  const [startIndex, setStartIndex] = useState(0);

  const onUp = () => {
    const cellCount = rows * cols;
    let index = startIndex - cellCount;
    if (index < 0)
      index = items.length - (items.length % cellCount || cellCount);
    setStartIndex(index);
  };

  const onDown = () => {
    let index = startIndex + rows * cols;
    if (index >= items.length) index = 0;
    setStartIndex(index);
  };

  const getData = (allItems, start, maxRowCount, maxColCount) => {
    if (start >= allItems.length && allItems.length !== 0) {
      setStartIndex(0);
      return [];
    }

    const maxItemCount = maxRowCount * maxColCount;
    // how many items will be shown
    const itemsCount = Math.min(maxItemCount, allItems.length - startIndex);

    let rowCount = maxRowCount;
    let colCount = maxColCount;
    if (allItems.length < maxItemCount) {
      rowCount = Math.ceil(itemsCount / maxColCount);
      colCount = Math.min(itemsCount, maxColCount);
    }
    const cellCount = colCount * rowCount;

    const end = Math.min(start + itemsCount, allItems.length);
    let activeItems = allItems.slice(start, end);
    if (activeItems.length < cellCount) {
      activeItems = [...activeItems, ...Array(cellCount - activeItems.length)];
    }

    const dataByRows = Array.from({ length: rowCount }, (_, rowIndex) =>
      activeItems.slice(rowIndex * colCount, (rowIndex + 1) * colCount)
    );
    return dataByRows;
  };

  const dataByRows = getData(items, startIndex, rows, cols);
  const onePage = rows * cols >= items.length;

  return (
    <div className="left-panel vertical-carousel">
      {!onePage && (
        <GazableButton
          gazable={gazable}
          className={
            demo !== "fisecondrst"
              ? "wide-button top-border-aligned"
              : "wide-button up-button"
          }
          onClick={onUp}
          key={`up${gazable ? "-gazable" : ""}`}
        >
          <AngelsUpIcon />
        </GazableButton>
      )}
      <table>
        <tbody>
          {dataByRows.map((rowItems, rowIndex) => (
            <tr key={rowIndex}>
              {rowItems.map((item, colIndex) => (
                <td key={colIndex}>{item}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!onePage && (
        <GazableButton
          gazable={gazable}
          className={
            demo !== "second"
              ? "wide-button bottom-border-aligned"
              : "wide-button down-button"
          }
          onClick={onDown}
          key={`down${gazable ? "-gazable" : ""}`}
        >
          <AngelsDownIcon />
        </GazableButton>
      )}
    </div>
  );
};

export default VerticalCarousel;
