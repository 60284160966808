export const Mode = {
  List: "List",
  Edit: "Edit",
  Watch: "Watch",
};

export const Theme = { Light: "light", Dark: "dark" };
export const Layout = { Grid4: "grid4", Grid6: "grid6" };

export const defaultVideoLists = {
  cats: [
    "y0sF5xhGreA",
    "hlajmIPiphk",
    "O58N8Mmv0QU",
    "mD2rWEowr-w",
    "ccePQPTCGP4",
    "V7e-i03HAS0",
    "Wo8yhLMemGk",
    "BR7wHvD9B6Y",
    "eY2tfLv1OJ8",
    "82MaJuoUiH8",
    "FEHSMnoHIXQ",
  ],
  dogs: [
    "pxn0wL_uSm4",
    "VAH-ixdFWFs",
    "g_bwUKojKZ8",
    "8t1dGcH4_4Y",
    "sD9gTAFDq40",
  ],
  myList: [
    "VC_FN7QrGb4",
    "PX_tyDZylSc",
    "vDVFk56PTd8",
    "5oH9Nr3bKfw",
    "i7pA9n7iTTo",
    "PcWHKyMRrew",
  ],
};
