const VideoIframe = ({ url, ...props }) => (
  <iframe
    title={url}
    src={url}
    referrerPolicy="strict-origin-when-cross-origin"
    allowFullScreen
    controls={0}
    {...props}
  ></iframe>
);

export default VideoIframe;
