import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { DoneIcon } from "./Icons";
import { useEffect, useState } from "react";

function UrlEditor({ initialUrl, onUpdate }) {
  const [url, setUrl] = useState({ value: initialUrl, changed: false });

  useEffect(() => {
    setUrl(initialUrl);
  }, [initialUrl]);

  const saveUrl = () => {
    onUpdate(url.value);
    setUrl({ value: url.value, changed: false });
  };

  const changeUrl = (e) => {
    setUrl({ value: e.target.value, changed: true });
  };

  return (
    <InputGroup>
      <Form.Control
        type="text"
        value={url.value}
        placeholder="YouTube Video URL"
        aria-describedby="url-button"
        onChange={changeUrl}
      />
      <Button
        className="small-button"
        variant="outline-secondary"
        id="url-button"
        disabled={!url.changed}
        onClick={saveUrl}
      >
        <DoneIcon />
      </Button>
    </InputGroup>
  );
}

export default UrlEditor;
