import YouTube from "react-youtube";

const YouTubePlayer = ({ videoId }) => {
  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      loop: 1,
    },
  };

  return <YouTube iframeClassName="full-space" videoId={videoId} opts={opts} />;
};

export default YouTubePlayer;
