import { useState } from "react";

const defaultImageDimensions = { width: 120, height: 90 }; // Known dimensions of YouTube's default image

const VideoThumbnail = ({ videoId, ...props }) => {
  const [fallbackUrl, setFallbackUrl] = useState(null);

  const initialUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg?`;

  const handleLoad = () => {
    if (!fallbackUrl) {
      const img = new Image();
      img.src = initialUrl;

      img.onload = () => {
        if (
          img.width === defaultImageDimensions.width &&
          img.height === defaultImageDimensions.height
        ) {
          fallback();
        }
      };
    }
  };

  const handleError = () => {
    if (!fallback) fallback();
  };

  const fallback = () => {
    const fallbackUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    setFallbackUrl(fallbackUrl);
  };

  return (
    <img
      alt="Youtube Video Thumbnail"
      src={fallbackUrl || initialUrl}
      onError={handleError}
      onLoad={handleLoad}
      {...props}
    />
  );
};

export default VideoThumbnail;
